<template>
  <div v-if="params.value ">
    <div
      v-if="setLoadStatusColor(params.data.load_status).label === 'Delivered'"
    >
      <span v-if="showCost">{{ params.data.revenue_cost | priceToUsd }}</span>
      <span v-else> Invoice pending </span>
    </div>
    <div v-else>
      <span>{{ params.data.revenue_cost | priceToUsd }}</span>
    </div>
  </div>
</template>
<script>
import LoadStatusType from "../../Extend/LoadStatusType";

export default {
  name: "LoadRevenueCost",
  data() {
    return {
      showCost: false,
    };
  },
  mixins: [LoadStatusType],
  created() {
    this.showCost = this.$store.getters["login/getTokenInfo"].show_cost;
  },
};
</script>
