<template>
  <div class="container-filters">
    <div>
      <span class="container-filters__titles">
        <i class="el-icon-price-tag mr-1"></i>
        Load Status
      </span>
      <div class="ml-2">
        <el-checkbox
          v-for="item in optionsStatus"
          :key="item.text"
          v-model="item.value"
          :disabled="item.disabled"
        >
          {{ item.text }}
        </el-checkbox>
      </div>
    </div>
    <div class="mt-2" style="heigth: 100%">
      <span class="container-filters__titles">
        <i class="el-icon-shopping-bag-2 mr-1"></i>
        Accesorials
      </span>
      <div>
        <div
          v-for="button in optionsAccesorials"
          :key="button.label"
          :class="[
            { 'chip-accesorials-active': button.active },
            'chip-accesorials mt-1',
          ]"
          @click="toggleButton(button)"
        >
          <img class="container-filters__icons" :src="button.iconSrc" alt="" />
          <span class="ml-1 container-filters__titles">{{ button.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable global-require */
import LoadStatusType from '../../Extend/LoadStatusType';

export default {
  name: "FiltersAccesorialsAndStatuses",
  mixins: [LoadStatusType],
  props: {
    data: Object,
    reset: Boolean,
    selectedDate: {
      type: String,
      default: "create_date",
    },
  },
  data() {
    return {
      optionsAccesorials: [
        {
          iconSrc: require("@/assets/scss/icons/hazmat.svg"),
          label: "Hazmat",
          active: false,
          options: "Hazmat,Hazmat Explosives 1.4,Hazmat Toxic or Poison 6.1",
        },
        {
          iconSrc: require("@/assets/scss/icons/airport.svg"),
          label: "Airport/CFS",
          active: false,
          options: "Airport Pick Up,Airport Delivery,CFS Pick Up, CFS Delivery",
        },
        {
          iconSrc: require("@/assets/scss/icons/residential.svg"),
          label: "Residential",
          active: false,
          options: "Residential Pick Up,Residential Delivery",
        },
        {
          iconSrc: require("@/assets/scss/icons/limited.svg"),
          label: "Limited Access",
          active: false,
          options: "Limited access Pick Up,Limited Access Delivery",
        },
        {
          iconSrc: require("@/assets/scss/icons/guaranteed.svg"),
          label: "Guaranteed/Expedited",
          active: false,
          options: "Guaranteed Transit Time,Expedited",
        },
        {
          iconSrc: require("@/assets/scss/icons/insurance.svg"),
          label: "Insurance",
          active: false,
          options: "Insurance",
        },
      ],
    };
  },
  watch: {
    reset() {
      if (this.reset) {
        this.resetFilters();
        this.$emit("resetFilters", false);
      }
    },
    optionsStatus: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit("changeFiltersStatus", this.optionsStatus);
      },
    },
    optionsAccesorials: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit("changeFilterAccesorials", this.optionsAccesorials);
      },
    },
    selectedDate() {
      if (this.selectedDate !== null && this.selectedDate !== undefined) {
        this.validateSelectedSatatus(this.selectedDate);
      }
    },
  },
  methods: {
    toggleButton(button) {
      button.active = !button.active;
    },
    infoFilter(data) {
      if (
        data !== undefined &&
        data !== null &&
        this.validateDefaultFilter(data)
      ) {
        this.resetFilters();
        if (data.included_accessorials !== "") {
          this.optionsAccesorials.forEach((object) => {
            object.active = data.included_accessorials.includes(object.options);
          });
        }
        if (data.statuses.length !== null && data.statuses.length !== 0) {
          this.optionsStatus.forEach((object) => {
            object.value = data.statuses
              .toString()
              .includes(object.info.toString());
          });
        }
        this.validateSelectedSatatus(this.selectedDate);
      } else {
        this.optionsStatus.forEach((option) => {
          option.value = true;
        });
      }
    },
    validateDefaultFilter(data) {
      let defaultFilter = false;
      Object.entries(data).forEach(([key, value]) => {
        if (key !== "statuses" && value !== "") {
          defaultFilter = true;
        } else if (key === "statuses" && value.length !== 0) {
          defaultFilter = true;
        }
      });
      return defaultFilter;
    },
    resetFilters() {
      this.optionsStatus.forEach((option) => {
        option.value = false;
      });
      this.optionsAccesorials.forEach((option) => {
        option.active = false;
      });
    },
    validateSelectedSatatus(date) {
      if (date === "Actual Delivery Date") {
        this.optionsStatus.forEach((option) => {
          if (option.item !== "Delivered") {
            option.value = false;
            option.disabled = true;
          } else {
            option.disabled = false;
          }
        });
      } else if (
        date === "Estimated Delivery Date" ||
        date === "Actual Pickup Date"
      ) {
        this.optionsStatus.forEach((option) => {
          if (option.item !== "Delivered" && option.item !== "In Transit") {
            option.value = false;
            option.disabled = true;
          } else {
            option.disabled = false;
          }
        });
      } else {
        this.optionsStatus.forEach((option) => {
          option.disabled = false;
        });
      }
    },
  },
  mounted() {
    this.infoFilter(this.data);
  },
};
</script>

<style lang="scss" scoped>
.container-filters {
  padding: 4px;
  &__titles {
    font-size: 11px;
    font-family: $font-family-portal;
    color: #606266;
  }
  &__icons {
    width: 10px;
    height: 10px;
  }
}

// Filtros de fechas
.chip-accesorials {
  font-weight: 500;
  text-align: center;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #dcdfe6;
  border-radius: 15px;
  margin: 0px;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  font-size: 12px;
  padding: 7px 8px;
}
.chip-accesorials-active {
  border-radius: 15px;
  margin: 0px;
  color: $color-border-container;
  border-color: $color-border-container;
  background-color: rgba(0, 185, 204, 0.0588235294);
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: $color-border-container;
  border-color: $color-border-container;
}
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 8px;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}
::v-deep .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 11px;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #606266;
}
.checkbox-group-1 {
  display: flex;
  flex-wrap: wraps;
}
</style>
