<template>
  <div v-if="params.value" class="chip-success">
    <span :style="'color:' + chipColor(params.value)">{{
      chipText(params.value)
    }}</span>
  </div>
</template>
<script>
import LoadStatusType from "../../Extend/LoadStatusType";

export default {
  name: "StatusLoad",
  mixins: [LoadStatusType],
  methods: {
    chipColor(value) {
      let color = "default";
      color = this.setLoadStatusColor(value).color;
      return color;
    },
    chipText(value) {
      let label = "default";
      label = this.setLoadStatusColor(value).label;
      return label;
    },
  },
};
</script>
