<template>
  <div v-if="params.value">
    <span v-if="params.value">
      {{ params.value | dateFormat }}
    </span>
  </div>
</template>
<script>
export default {
  name: "LoadDates",
};
</script>

<style lang="scss" >
.expired-date {
  color: red;
}
</style>
