<template>
  <div v-if="params.value">
    <span
      v-b-popover.hover.top="$t('myLoads.myLoadsTable.epd')"
      :class="
        compareDatesEpd(
          params.value,
          setLoadStatusColor(params.data.load_status)
        )
      "
    >
      {{ params.value | dateFormat }}
    </span>
  </div>
</template>
<script>
import dayjs from "dayjs";
import LoadStatusType from "../../Extend/LoadStatusType";

export default {
  name: "LoadDateExpectedEpd",
  mixins: [LoadStatusType],
  data() {
    return {
      currentDate: null,
    };
  },
  created() {
    this.currentDate = dayjs().format("MM-DD-YYYY");
  },
  methods: {
    compareDatesEpd(loadDate, loadStatus) {
      if (loadStatus.label.toLowerCase() === "assigned") {
        const formatLoadDate = dayjs(loadDate).format("MM-DD-YYYY");
        const loadDateIsBeforeCurrentDate = dayjs(formatLoadDate).isBefore(
          this.currentDate
        );
        if (loadDateIsBeforeCurrentDate) {
          return "expired-date";
        }
        return null;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" >
.expired-date {
  color: red;
}
</style>
