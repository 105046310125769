<template>
  <div v-if="params.value" class="main-div">
    <i v-b-popover.hover.top="'Copy text'" v-if="activeImg">
      <img
        v-if="activeImg"
        class="icon-filters button-div mr-1"
        src="@/assets/scss/icons/copy-regular.svg"
        alt=""
        @click="open()"
      />
    </i>
    <a
      @mouseover="activeImg = true"
      :href="openWindow"
      @click="rowSelected"
      class="link-reference ml-1"
      style="z-index: 100"
      >{{ params.value }}</a
    >
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import copy from "copy-to-clipboard";
import LoadStatusType from "../../Extend/LoadStatusType";

export default {
  name: "load-shp",
  mixins: [LoadStatusType],
  components: {
    copy,
  },
  data() {
    return {
      activeImg: false,
    };
  },
  computed: {
    openWindow() {
      const loadStatusType = this.setLoadStatusColor(
        this.params.data.load_status
      );
      let routeName = "";
      if (loadStatusType.label === "Quoted") {
        routeName = "CarriersInfo";
      } else {
        routeName = "LoadsDetails";
      }
      const routeData = this.$router.resolve({
        name: routeName,
        params: { loadId: this.params.data.id },
      });
      return routeData.href;
    },
  },
  methods: {
    open() {
      copy(this.params.value);
      this.activeImg = false;
    },
    rowSelected(event) {
      if (event.ctrlKey || event.metaKey) {
        // Verifica si se presionó "Control" o "Command" (en macOS)
        // Navegar a una dirección diferente para Ctrl+click
      } else {
        // Navegar a una dirección diferente para clic normal
        const loadStatusType = this.setLoadStatusColor(
          this.params.data.load_status
        );
        let routeName = "";
        if (loadStatusType.label === "Quoted") {
          routeName = "CarriersInfo";
        } else {
          routeName = "LoadsDetails";
        }
        this.$router.push({
          name: routeName,
          params: { loadId: this.params.data.id },
        });

        this.$store.commit("load/setSearchText", "");
        event.preventDefault();
      }
      // Evita el comportamiento de enlace predeterminado
    },
  },
};
</script>
<style lang="scss">
.icon-filters {
  width: 11px;
  height: 11px;
  cursor: pointer;
}

.link-reference {
  color: rgba(0, 0, 0, 0.87);
}

.link-reference:hover {
  color: $color-border-container;
}

.main-div {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.button-div {
  display: none;
}
.main-div:hover .button-div {
  display: block;
}
</style>
