<template>
  <div class="container-dates">
    <span class="container-dates__titles">
      <i class="el-icon-date mr-1"></i>
      Dates
    </span>
    <div class="ml-1" v-for="(item, index) in optionsDates" :key="index">
      <el-checkbox
        v-model="item.checked"
        @change="changeCheckbox(item.value)"
        @input="selectedDate(item.text)"
      >
        <span :class="[{ 'validation-dates-label': item.checked }]">
          {{ item.text }}
          <span style="color: red" v-if="item.checked">*</span></span
        >
      </el-checkbox>
      <div v-if="item.view && item.checked" class="container-dates__picker">
        <el-date-picker
          v-model="item.date_1"
          type="date"
          placeholder="Initial"
          format="yyyy-MM-dd"
          @change="selectedInitialDate(item.date_1)"
          :class="[
            {
              'validation-dates':
                validationFilters &&
                item.checked &&
                (item.date_1 === null || item.date_1 === ''),
            },
          ]"
        >
        </el-date-picker>
        <span class="container-dates__label">-</span>
        <el-date-picker
          v-model="item.date_2"
          type="date"
          placeholder="Finish"
          format="yyyy-MM-dd"
          :picker-options="pickerOptionsFinish"
          :class="[
            {
              'validation-dates':
                validationFilters &&
                item.checked &&
                (item.date_2 === null || item.date_2 === ''),
            },
          ]"
        >
        </el-date-picker>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable global-require */

export default {
  name: "FiltersDates",
  props: {
    data: Object,
    reset: Boolean,
    validationFilters: Boolean,
  },
  data() {
    return {
      startDate: null,
      numberFilters: 0,
      pickerOptionsFinish: {
        disabledDate: this.disabledDateFinish,
      },
      dateMappings: {
        1: { gt: "created_date_gt", lt: "created_date_lt" },
        2: { gt: "expected_ship_date_gt", lt: "expected_ship_date_lt" },
        3: { gt: "actual_pickup_date_gt", lt: "actual_pickup_date_lt" },
        4: { gt: "expected_delivery_date_gt", lt: "expected_delivery_date_lt" },
        5: { gt: "actual_delivery_date_gt", lt: "actual_delivery_date_lt" },
      },
      optionsDates: [
        {
          value: 1,
          checked: false,
          view: false,
          date_1: "",
          date_2: "",
          text: "Created Date",
          disabledStatus: [],
        },
        {
          value: 2,
          checked: false,
          view: false,
          date_1: "",
          date_2: "",
          text: "Estimated Pickup Date",
        },
        {
          value: 3,
          checked: false,
          view: false,
          date_1: "",
          date_2: "",
          text: "Actual Pickup Date",
        },
        {
          value: 4,
          checked: false,
          view: false,
          date_1: "",
          date_2: "",
          text: "Estimated Delivery Date",
        },
        {
          value: 5,
          checked: false,
          view: false,
          date_1: "",
          date_2: "",
          text: "Actual Delivery Date",
        },
      ],
      pickerOptions: {
        disabledDate: this.disabledDate,
        onPick: this.pick,
      },
    };
  },
  watch: {
    reset() {
      if (this.reset) {
        this.resetDate();
      }
    },
    optionsDates: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit("changeFiltersDate", this.optionsDates);
      },
    },
  },
  methods: {
    infoFilter(data) {
      if (data !== null && this.validateDefaultFilter(data)) {
        this.numberFilters = 0;
        this.optionsDates.forEach((object) => {
          const mapping = this.dateMappings[object.value];
          if (
            data[mapping.gt] !== null &&
            data[mapping.lt] !== null &&
            data[mapping.gt] !== "" &&
            data[mapping.lt] !== ""
          ) {
            this.numberFilters += 1;
            object.date_1 = new Date(`${data[mapping.gt]}T00:00:00`);
            object.date_2 = new Date(`${data[mapping.lt]}T00:00:00`);
            object.checked = true;
            object.view = true;
          }
        });
      } else {
        this.calculateDateDefault();
      }
    },
    validateDefaultFilter(data) {
      let defaultFilter = false;
      Object.entries(data).forEach(([key, value]) => {
        if (key !== "statuses" && value !== "") {
          defaultFilter = true;
        } else if (key === "statuses" && value.length !== 0) {
          defaultFilter = true;
        }
      });
      return defaultFilter;
    },
    resetDate() {
      this.optionsDates.forEach((option) => {
        option.checked = false;
        option.view = false;
        option.date_1 = "";
        option.date_2 = "";
      });
    },
    selectedDate(value) {
      this.$emit("validateStatusSelected", value);
    },
    changeCheckbox(value) {
      this.optionsDates.forEach((object) => {
        if (object.value === value) {
          object.view = true;
          if (!object.checked) {
            this.startDate = null;
            object.date_1 = "";
            object.date_2 = "";
          }
        } else {
          object.view = false;
          object.checked = false;
          object.date_1 = "";
          object.date_2 = "";
          this.startDate = null;
        }
      });
    },
    selectedInitialDate(value) {
      this.startDate = value;
    },
    calculateDateDefault() {
      // Obtener la fecha actual
      const currentDate = new Date();
      // Calcular la fecha hace 9 semanas
      const nineWeeksAgo = new Date(currentDate);
      this.startDate = new Date(
        nineWeeksAgo.setDate(currentDate.getDate() - 18 * 7)
      );
      this.optionsDates[0].checked = true;
      this.optionsDates[0].view = true;
      this.optionsDates[0].date_1 = this.startDate;
      this.optionsDates[0].date_2 = currentDate;
    },
    disabledDateFinish(date) {
      if (this.startDate) {
        return this.startDate > date;
      }
      return false;
    },
  },
  mounted() {
    this.infoFilter(this.data);
  },
};
</script>

<style lang="scss" scoped>
.container-dates {
  height: 230px;
  &__picker {
    display: flex;
    text-align: center;
    margin: 0px 2px 2px 2px;
  }
  &__titles {
    font-size: 11px;
    font-family: $font-family-portal;
    color: #606266;
  }
  &__label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    padding: 4px;
    font-family: $font-family-portal;
  }
}

::v-deep .el-input__inner {
  border-radius: 5px;
  border: 1px solid #efefef;
  box-sizing: border-box;
  color: #606266;
  height: 25px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  font-family: "Montserrat";
  font-size: 11px;
}
::v-deep .el-input__inner:hover {
  border-color: #efefef;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 139px;
}
::v-deep .el-input__icon {
  line-height: 0px;
  height: 100%;
}
::v-deep .el-date-table th span {
  font-family: $font-family-portal;
}

.checkbox-group-1 {
  display: flex;
  flex-wrap: wraps;
}

::v-deep .validation-dates .el-input__inner {
  border: 1px solid #dc3545b3;
  border-radius: 5px;
}
.validation-dates-label {
  color: $color-border-container;
}
</style>
