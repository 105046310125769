<template>
  <div v-click-outside="hide">
    <i v-b-popover.hover.top="'Filters'">
      <el-badge :value="numberFilters">
        <el-button class="container-filter mr-2" @click="changeStatusFilters()"
          ><img
            class="container-filter__icons"
            src="@/assets/scss/icons/filter.svg"
            alt="filter-icon"
          />
        </el-button>
      </el-badge>
    </i>
    <div v-if="activeMenu" class="container-filter__options m-2">
      <div class="filters-options">
        <div class="filters-options__categorys">
          <div class="filters-options__searchs background-overlay">
            <FiltersSearchs
              :data="data"
              :reset="activeResetFilters"
              @changeFilersSearchs="changeFilersSearchs"
            ></FiltersSearchs>
          </div>
          <div class="filters-options__dates background-overlay mt-2">
            <FiltersDates
              :data="data"
              :reset="activeResetFilters"
              :validationFilters="validationFilters"
              @changeFiltersDate="changeFiltersDate"
              @validateStatusSelected="validateStatusSelected"
            ></FiltersDates>
          </div>
        </div>
        <div class="filters-options__categorys background-overlay">
          <FiltersAccesorialsStatus
            :data="data"
            :reset="activeResetFilters"
            :selectedDate="selectedDate"
            @changeFiltersStatus="changeFiltersStatus"
            @changeFilterAccesorials="changeFilterAccesorials"
          ></FiltersAccesorialsStatus>
        </div>
      </div>
      <div class="filters-footer" v-loading="loading">
        <button :class="'filters-footer__buttons'" @click="applyFilters()">Apply</button>
        <button class="filters-footer__buttons" @click="resetFilters()">Reset</button>
        <button class="filters-footer__buttons" @click="activeMenu = false">Close</button>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable global-require */
import FiltersSearchs from "@/components/FiltersMyLoad/FiltersSearchs.vue";
import FiltersDates from "@/components/FiltersMyLoad/FiltersDates.vue";
import FiltersAccesorialsStatus from "@/components/FiltersMyLoad/FiltersAccesorialsStatus.vue";

export default {
  name: "my-loads-filters-advance",
  components: {
    FiltersSearchs,
    FiltersDates,
    FiltersAccesorialsStatus,
  },
  props: {
    active: {
      type: Boolean,
    },
    data: {
      type: Object,
    },
  },
  directives: {
    "click-outside": {
      bind: (el, binding, vnode) => {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unbind: (el) => {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    },
  },
  data() {
    return {
      filtersSearchs: {
        origin: "",
        destination: "",
        carrier: "",
        billing_reference: "",
        customer: "",
        modes: []
      },
      dateMappings: {
        1: { gt: "created_date_gt", lt: "created_date_lt" },
        2: { gt: "expected_ship_date_gt", lt: "expected_ship_date_lt" },
        3: { gt: "actual_pickup_date_gt", lt: "actual_pickup_date_lt" },
        4: { gt: "expected_delivery_date_gt", lt: "expected_delivery_date_lt" },
        5: { gt: "actual_delivery_date_gt", lt: "actual_delivery_date_lt" },
      },
      optionsDates: [
        {
          value: 1,
          checked: true,
          view: true,
          date_1: "",
          date_2: "",
          text: "Created Date",
        },
        {
          value: 2,
          checked: false,
          view: false,
          date_1: "",
          date_2: "",
          text: "Estimated Pickup Date",
        },
        {
          value: 3,
          checked: false,
          view: false,
          date_1: "",
          date_2: "",
          text: "Actual Pickup Date",
        },
        {
          value: 4,
          checked: false,
          view: false,
          date_1: "",
          date_2: "",
          text: "Estimated Delivery Date",
        },
        {
          value: 5,
          checked: false,
          view: false,
          date_1: "",
          date_2: "",
          text: "Actual Delivery Date",
        },
      ],
      optionsStatus: [
        {
          item: "Quoted",
          text: "Quoted",
          value: true,
          info: ["unassigned", "quotes requested", "quotes received", "quoted"],
        },
        {
          item: "Assigned",
          text: "Assigned",
          value: true,
          info: ["tendered", "dispatched", "assigned"],
        },
        {
          item: "In Transit",
          text: "In Transit",
          value: true,
          info: ["in transit"],
        },
        {
          item: "Delivered",
          text: "Delivered",
          value: true,
          info: ["delivered", "completed"],
        },
        {
          item: "Cancelled",
          text: "Cancelled",
          value: true,
          info: ["expired", "declined", "cancelled"],
        },
      ],
      optionsAccesorials: [
        {
          iconSrc: require("@/assets/scss/icons/hazmat.svg"),
          label: "Hazmat",
          active: false,
          options: "Hazmat,Hazmat Explosives 1.4,Hazmat Toxic or Poison 6.1",
        },
        {
          iconSrc: require("@/assets/scss/icons/airport.svg"),
          label: "Airport/CFS",
          active: false,
          options: "Airport Pick Up,Airport Delivery,CFS Pick Up, CFS Delivery",
        },
        {
          iconSrc: require("@/assets/scss/icons/residential.svg"),
          label: "Residential",
          active: false,
          options: "Residential Pick Up,Residential Delivery",
        },
        {
          iconSrc: require("@/assets/scss/icons/limited.svg"),
          label: "Limited Access",
          active: false,
          options: "Limited access Pick Up,Limited Access Delivery",
        },
        {
          iconSrc: require("@/assets/scss/icons/guaranteed.svg"),
          label: "Guaranteed/Expedited",
          active: false,
          options: "Guaranteed,Expedited",
        },
        {
          iconSrc: require("@/assets/scss/icons/insurance.svg"),
          label: "Insurance",
          active: false,
          options: "Insurance",
        },
      ],
      objectFilters: {
        shp: "",
        origin: "",
        destination: "",
        carrier: "",
        billing_reference: "",
        customer: "",
        created_date_gt: "",
        created_date_lt: "",
        actual_pickup_date_gt: "",
        actual_pickup_date_lt: "",
        actual_delivery_date_gt: "",
        actual_delivery_date_lt: "",
        expected_ship_date_gt: "",
        expected_ship_date_lt: "",
        expected_delivery_date_gt: "",
        expected_delivery_date_lt: "",
        included_accessorials: "",
        limit: "",
        skip: "",
        order_by: "",
        statuses: [],
      },
      activeResetFilters: false,
      validationFilters: false,
      loading: false,
      activeMenu: false,
      numberFilters: 0,
      columnDates: "",
      selectedDate: "Created Date",
    };
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler() {
        if (this.data !== undefined && this.data !== null) {
          this.numberFilters = 0;
          this.infoFilter(this.data);
        }
      },
    },
    active() {
      this.activeMenu = !this.active ? false : this.activeMenu;
    },
  },
  methods: {
    changeStatusFilters() {
      this.activeMenu = !this.activeMenu;
      this.$emit("changeStatusFilters", true);
    },
    changeFilersSearchs(value) {
      this.filtersSearchs = value;
    },
    changeFiltersDate(value) {
      this.optionsDates = value;
    },
    changeFiltersStatus(value) {
      this.optionsStatus = value;
    },
    changeFilterAccesorials(value) {
      this.optionsAccesorials = value;
    },
    infoFilter(data) {
      if (data.origin && data.origin !== '') {
        this.numberFilters += 1;
      }
      if (data.destination && data.destination !== '') {
        this.numberFilters += 1;
      }
      if (data.carrier && data.carrier !== '') {
        this.numberFilters += 1;
      }
      if (data.billing_reference && data.billing_reference !== '') {
        this.numberFilters += 1;
      }
      if (data.customer && data.customer !== '') {
        this.numberFilters += 1;
      }
      if (data.included_accessorials && data.included_accessorials !== '') {
        this.numberFilters += 1;
      }
      if (data.accessorial_id && data.accessorial_id !== "") {
        this.numberFilters += 1;
      }
      if (data.statuses && data.statuses.length !== 0 && data.statuses.length !== null) {
        this.numberFilters += 1;
      }
      if (data.state_search && data.state_search !== "") {
        this.numberFilters += 1;
      }
      if (data.modes && data.modes.length !== 0 && data.modes.length !== null) {
        this.numberFilters += 1;
      }
      this.optionsDates.forEach((object) => {
        const mapping = this.dateMappings[object.value];
        if (data[mapping.gt] !== "" && data[mapping.lt] !== "") {
          this.numberFilters += 1;
          this.validateDateSelected(data);
        }
      });
    },
    validateDateSelected(data) {
      if (data.created_date_gt !== "") {
        this.selectedDate = "Created Date";
      } else if (data.expected_ship_date_gt !== "") {
        this.selectedDate = "Estimated Pickup Date";
      } else if (data.actual_pickup_date_gt !== "") {
        this.selectedDate = "Actual Pickup Date";
      } else if (data.expected_delivery_date_gt !== "") {
        this.selectedDate = "Estimated Delivery Date";
      } else if (data.actual_delivery_date_gt !== "") {
        this.selectedDate = "Actual Delivery Date";
      }
    },
    formatDate(date) {
      const dateObject = new Date(date);
      const year = dateObject.getFullYear();
      const month = String(dateObject.getMonth() + 1).padStart(2, "0");
      const day = String(dateObject.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    resetFilters() {
      this.activeResetFilters = true;
      this.numberFilters = 0;
      // this.$emit("resetFilters", null);
    },
    applyFilters() {
      this.numberFilters = 0;
      this.loading = true;
      this.activeResetFilters = false;
      this.validationFilters = false;
      const objectFilters = JSON.parse(JSON.stringify(this.objectFilters));

      const statuses = this.optionsStatus
        .filter((option) => option.value)
        .flatMap((option) => option.info);

      const accessorials = this.optionsAccesorials
        .filter((option) => option.active)
        .map((option) => option.options);

      this.optionsDates.forEach((object) => {
        const mapping = this.dateMappings[object.value];
        if (object.checked) {
          if (
            object.date_1 !== null &&
            object.date_2 !== null &&
            object.date_1 !== "" &&
            object.date_2 !== ""
          ) {
            objectFilters[mapping.gt] = this.formatDate(object.date_1);
            objectFilters[mapping.lt] = this.formatDate(object.date_2);
            this.numberFilters += 1;
            this.columnDates = `${this.columnDates},${object.text}`;
            this.selectedDate = object.text;
          } else if (
            object.date_1 === null ||
            object.date_2 === null ||
            object.date_1 === "" ||
            object.date_2 === ""
          ) {
            this.validationFilters = true;
          }
        }
      });
      objectFilters.statuses = statuses;
      objectFilters.included_accessorials = accessorials.join(";");
      objectFilters.origin = this.filtersSearchs.origin;
      objectFilters.destination = this.filtersSearchs.destination;
      objectFilters.carrier = this.filtersSearchs.carrier;
      objectFilters.billing_reference = this.filtersSearchs.billing_reference;
      objectFilters.customer = this.filtersSearchs.customer;
      objectFilters.modes = this.filtersSearchs.modes;
      if (statuses.length !== 0) {
        this.numberFilters += 1;
      }
      if (accessorials.length !== 0) {
        this.numberFilters += 1;
      }
      if (this.filtersSearchs.origin !== "") {
        this.numberFilters += 1;
      }
      if (this.filtersSearchs.destination !== "") {
        this.numberFilters += 1;
      }
      if (this.filtersSearchs.carrier !== "") {
        this.numberFilters += 1;
      }
      if (this.filtersSearchs.billing_reference !== "") {
        this.numberFilters += 1;
      }
      if (this.filtersSearchs.customer !== "") {
        this.numberFilters += 1;
      }
      if (this.filtersSearchs.modes.length >= 1) {
        this.numberFilters += 1;
      }
      this.loading = false;
      if (!this.validationFilters) {
        setTimeout(() => {
          this.loading = false;
          this.activeMenu = false;
        }, 500);
        this.$emit("selectedFilters", objectFilters);
      }
    },
    hide() {
      this.activeMenu = false;
    },
    validateStatusSelected(value) {
      this.selectedDate = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-filter {
  padding: 0.4rem 0.4rem;
  background-color: #efefef;
  color: var(--color-primary-company);
  border: 2px solid var(--color-border-container);
  font-weight: 600;
  font-size: 13px;
  &:focus {
    background-color: $color-border-container;
    color: #fff;
  }
  &__icons {
    width: 18px;
    height: 18px;
  }
  &__options {
    border: 2px solid var(--color-border-container);
    border-radius: 5px;
    padding: 8px;
    width: 480px;
    background: #fff;
    position: absolute;
    transform: translate3d(73px, 30px, 0px);
    top: 88px;
    left: calc(100% - 665px);
    will-change: transform;
    z-index: 12;
    float: left;
    min-width: 10rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    font-family: $font-family-portal;
  }
}

.filters-options {
  display: flex;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 10px;
  margin: 0 auto;

  &__titles {
    font-size: 11px;
    font-family: $font-family-portal;
    color: #606266;
  }
  &__categorys {
    gap: 6px;
    padding: 1px;
  }
  &__searchs {
    height: 331px;
    &__inputs {
      flex-direction: row;
    }
  }
}
.filters-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  margin-top: 6px;
  gap: 8px;

  &__buttons {
    border-radius: 15px;
    border: 1px solid $color-border-container;
    background-color: #fff;
    color: $color-border-container;
    margin: 2px;
    font-weight: bold;
    height: 25px;
    width: 70px;
    font-size: 11px;
    &:hover {
      background-color: $color-border-container;
      color: #fff;
    }
    &:focus-within {
      background-color: $color-border-container;
      color: #fff;
    }
  }
  &__buttons-inactive {
    background-color: #f4f4f4;
    color: #c8c8ca;
    border: 1px solid #f4f4f4;
    border-radius: 15px;
    margin: 2px;
    font-weight: bold;
    height: 25px;
    width: 70px;
    font-size: 11px;
  }
}

.background-overlay {
  background: #efefefb3;
  border-radius: 10px;
  padding: 6px 0px 0px 6px;
}
.justify-center {
  display: flex;
  justify-content: center;
  align-content: center;
}
// Filtros de fechas
.chip-accesorials {
  font-weight: 500;
  text-align: center;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #dcdfe6;
  border-radius: 15px;
  margin: 0px;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  font-size: 12px;
  padding: 7px 8px;
}
.chip-accesorials-active {
  border-radius: 15px;
  margin: 0px;
  color: $color-border-container;
  border-color: $color-border-container;
  background-color: rgba(0, 185, 204, 0.0588235294);
}
.container-close {
  display: flex;
  justify-content: flex-end;
  &__button {
    background: none;
    border: none;
    color: $color-primary-trigger-button;
    &__icon {
      font-size: 18px;
    }
    padding-right: 0px;
  }
}

::v-deep .dropdown-menu {
  border: 2px solid $color-border-container;
  border-radius: 5px;
  padding: 8px;
  margin: 1px -480px 0;
  width: 480px;
  background: #fff;
}

::v-deep .el-input__inner {
  border-radius: 5px;
  border: 1px solid #efefef;
  box-sizing: border-box;
  color: #606266;
  height: 25px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 123px;
  font-family: "Montserrat";
  font-size: 11px;
}
::v-deep .el-input__inner:hover {
  border-color: #efefef;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 139px;
}
::v-deep .el-input__icon {
  line-height: 0px;
  height: 85%;
}
::v-deep .el-date-table th span {
  font-family: $font-family-portal;
}
::v-deep .p-dropdown-car-option label {
  font-size: 11px;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: $color-border-container;
  border-color: $color-border-container;
}
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 8px;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}
::v-deep .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 11px;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #606266;
}
::v-deep.item {
  margin-top: 0px;
  margin-right: 0px;
  z-index: 11;
}
::v-deep.el-badge__content.is-fixed {
  position: absolute;
  top: 7px;
  right: 28px;
  transform: translateY(-50%) translateX(100%);
}
::v-deep .el-badge__content,
.el-progress.is-exception .el-progress-bar__inner {
  background-color: $color-border-container;
}
::v-deep .el-badge__content.is-fixed {
  top: 5px;
  right: 22px;
  z-index: 10;
}
::v-deep .el-badge__content {
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-size: 11px;
  height: 18px;
  line-height: 18px;
  padding: 0 5px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #fff;
}
::v-deep .el-input__suffix {
  height: 100%;
  right: 4px;
  top: 3px;
  transition: all 0.3s;
  pointer-events: none;
}
::v-deep .el-input--suffix .el-input__inner {
  padding-right: 0px;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 123px;
}

.checkbox-group-1 {
  display: flex;
  flex-wrap: wraps;
}
::v-deep .custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 14px;
  height: 14px;
  opacity: 0;
}
::v-deep .el-button:focus,
.el-button:hover {
  color: $color-border-container;
  border-color: $color-border-container;
  background-color: #00b9cc0f;
}
.icon-filters {
  width: 10px;
  height: 10px;
}
::v-deep .el-alert__title {
  font-size: 12px;
  line-height: 18px;
}
.el-loading-mask {
  z-index: 10;
}

.alert-error {
  width: 264px;
  padding: 3px 9px;
  margin: 0px;
  box-sizing: border-box;
  border-radius: 5px;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  opacity: 1;
  display: flex;
  align-items: center;
  transition: opacity 0.2s;
  background: rgba(255, 0, 0, 0.1490196078);
  color: #ff000099;
  font-size: 11px;
}
</style>
