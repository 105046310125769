const modesPicklist = [
  {
    id: "a0k1I0000005NnyQAE",
    name: "LTL",
    ops_dept: "LTL",
    ops_division: "LTL",
  },
  {
    id: "a0k1I0000005NnzQAE",
    name: "FTL - Dry",
    ops_dept: "OTR",
    ops_division: "Full Truck Load",
  },
  {
    id: "a0k1I0000005No3QAE",
    name: "Heavy Hauling - Specialized",
    ops_dept: "OTR",
    ops_division: "Heavy Hauling",
  },
  {
    id: "a0k1I0000005eufQAA",
    name: "Drayage",
    ops_dept: "OTR",
    ops_division: "Drayage",
  },
  {
    id: "a0k1I000000WkMEQA0",
    name: "Reefer Full Truck Load",
    ops_dept: "TC",
    ops_division: "REEFER",
  },
  {
    id: "a0k1I000000WkMOQA0",
    name: "Expedited",
    ops_dept: "SS",
    ops_division: "Expedited",
  },
  {
    id: "a0k1I000000WkMTQA0",
    name: "Partial Van (LTL Spot Market)",
    ops_dept: "OTR",
    ops_division: "Full Truck Load",
  },
  {
    id: "a0k1I000000WkMYQA0",
    name: "Power Only - Towing - Driveaway",
    ops_dept: "OTR",
    ops_division: "Heavy Hauling",
  },
  {
    id: "a0k1I000000XQNKQA4",
    name: "Volume LTL",
    ops_dept: "LTL",
    ops_division: "LTL",
  },
  {
    id: "a0k1I000000XTr9QAG",
    name: "Car Carrier",
    ops_dept: "OTR",
    ops_division: "Full Truck Load",
  },
  {
    id: "a0k1I00000B6pAUQAZ",
    name: "Transloading - Drayage",
    ops_dept: "OTR",
    ops_division: "Drayage",
  },
  {
    id: "a0k1I00000B6pAVQAZ",
    name: "Intermodal/Rail",
    ops_dept: "OTR",
    ops_division: "Full Truck Load",
  },
  {
    id: "a0k1I00000B6pAWQAZ",
    name: "Air Freight Domestic",
    ops_dept: "SS",
    ops_division: "Expedited",
  },
  {
    id: "a0k1I00000B6pAXQAZ",
    name: "HH - Transloading",
    ops_dept: "OTR",
    ops_division: "Heavy Hauling",
  },
  {
    id: "a0k1I00000B6pAYQAZ",
    name: "Parcel",
    ops_dept: "LTL",
    ops_division: "LTL",
  },
  {
    id: "a0k1I00000B76OTQAZ",
    name: "Partial F/SD (LTL Spot Market)",
    ops_dept: "OTR",
    ops_division: "Heavy Hauling",
  },
  {
    id: "a0k1I00000B76OiQAJ",
    name: "Reefer Less than Truckload",
    ops_dept: "TC",
    ops_division: "REEFER",
  },
  {
    id: "a0k1I00000B7RocQAF",
    name: "FTL - F/SD",
    ops_dept: "OTR",
    ops_division: "Heavy Hauling",
  },
  {
    id: "a0k1I00000B7T2nQAF",
    name: "Transloading - FTL",
    ops_dept: "OTR",
    ops_division: "Full Truck Load",
  },
  {
    id: "a0k1I00001eyNptQAE",
    name: "Super Load / Heavy Hauling",
    ops_dept: "OTR",
    ops_division: "Heavy Hauling",
  },
  {
    id: "a0k1I00001g7DBZQA2",
    name: "LTL Mexico",
    ops_dept: "SS",
    ops_division: "Mexico",
  },
  {
    id: "a0k1I00001g7DBeQAM",
    name: "FTL Mexico",
    ops_dept: "SS",
    ops_division: "Mexico",
  },
  {
    id: "a0k1I00001g7DBjQAM",
    name: "LTL Offshore",
    ops_dept: "SS",
    ops_division: "SS",
  },
  {
    id: "a0k1I00001l5PmLQAU",
    name: "Crating And Packaging",
    ops_dept: "SS",
    ops_division: "SS",
  },
  {
    id: "a0k3s00000aZwb3AAC",
    name: "LTL Canada",
    ops_dept: "SS",
    ops_division: "Canada",
  },
  {
    id: "a0k3s00000abN0hAAE",
    name: "OZ FTL F/SD",
    ops_dept: "OTR",
    ops_division: "Heavy Hauling",
  },
  {
    id: "a0k3s00000bS0WoAAK",
    name: "Transloading Reefer",
    ops_dept: "TC",
    ops_division: "REEFER",
  },
  {
    id: "a0k3s00000bhZi6AAE",
    name: "Reefer Drayage",
    ops_dept: "TC",
    ops_division: "REEFER",
  },
  {
    id: "a0k3s00000d5KjkAAE",
    name: "FTL - Dry Team Drivers",
    ops_dept: "OTR",
    ops_division: "Full Truck Load",
  },
  {
    id: "a0k3s00000d5KjlAAE",
    name: "FTL - F/SD Team Drivers",
    ops_dept: "OTR",
    ops_division: "Heavy Hauling",
  },
  {
    id: "a0k3s00000d6MfTAAU",
    name: "Reefer Full Truck Load Team Drivers",
    ops_dept: "TC",
    ops_division: "REEFER",
  },
  {
    id: "a0k3s00000d6xAlAAI",
    name: "Insurance",
    ops_dept: "Insurance",
    ops_division: "Insurance",
  },
  {
    id: "a0k3s00000d7CKKAA2",
    name: "Customs Broker Services",
    ops_dept: "Customs Broker Services",
    ops_division: "Customs Broker Services",
  },
  {
    id: "a0k3s00000ibGSMAA2",
    name: "Fulfillment",
    ops_dept: "Fulfillment",
    ops_division: "Fulfillment",
  },
  {
    id: "a0k3s00000kpTnxAAE",
    name: "Reefer Consolidated",
    ops_dept: "TC",
    ops_division: "REEFER",
  },
  {
    id: "a0kRc000004JlvNIAS",
    name: "IT Services",
    ops_dept: null,
    ops_division: null,
  },
  {
    id: "a0kRc000004sDcIIAU",
    name: "Partial Reefer Full Truck Load Team Drivers",
    ops_dept: "TC",
    ops_division: "REEFER",
  },
  {
    id: "a0kRc000004sETVIA2",
    name: "Partial Reefer Full Truck Load",
    ops_dept: "TC",
    ops_division: "REEFER",
  },
];

export default modesPicklist;
