<template>
  <div v-if="params.value" class="customer-cell" :title="params.value?.name">
    <label class="customer-cell__text">{{ params.value?.name || "" }}</label>
  </div>
</template>
<script>
export default {
  name: "CustomerCell",
};
</script>

<style lang="scss" scoped>
.customer-cell {
  // width: 100%;
  &__text {
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
