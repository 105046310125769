<template>
  <div v-if="params.value" class="main-div">
    <i v-b-popover.hover.top="'Copy text'" v-if="activeImg">
      <img
        v-if="activeImg"
        class="icon-filters button-div mr-1"
        src="@/assets/scss/icons/copy-regular.svg"
        alt=""
        @click="copyLink()"
      />
    </i>
    <a
      v-if="linkTracking !== ''"
      @mouseover="activeImg = true"
      class="link-reference ml-1"
      target="_blank"
      :href="linkTracking"
      >{{ params.value }}
    </a>
    <a
      v-if="linkTracking === ''"
      @mouseover="activeImg = true"
      class="link-reference ml-1"
      @click="undefinedLink()"
      >{{ params.value }}
    </a>
  </div>
</template>
<script>
/* eslint-disable vue/no-unused-components */
import copy from "copy-to-clipboard";
import CarrierTrackingLink from "../../Extend/CarrierTrackingLink";

export default {
  name: "load-pro-number",
  components: {
    copy,
  },
  data() {
    return {
      activeImg: false,
    };
  },
  mixins: [CarrierTrackingLink],
  computed: {
    linkTracking() {
      return this.searchTrackingLink(
        this.params.data.carrier.name,
        this.params.value
      );
    },
  },
  methods: {
    undefinedLink() {
      this.swal({
        title: "Info",
        text: this.$t("myLoads.messageLinkCarrier"),
        icon: "warning",
      });
    },
    copyLink() {
      copy(this.params.value);
      this.activeImg = false;
    },
  },
};
</script>
<style lang="scss">
.link-reference {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
}

.link-reference:hover {
  color: $color-border-container;
}
.icon-filters {
  width: 13px;
  height: 13px;

  &:hover {
    color: blue;
  }
}
.main-div {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.button-div {
  display: none;
}
.main-div:hover .button-div {
  display: block;
}
</style>
