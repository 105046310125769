<template>
  <div v-click-outside="hide">
    <i v-b-popover.hover.top="'Add Columns'">
      <el-badge :value="numberColumns" class="item">
        <el-button class="container-filter mr-2" @click="changeStatusColumns()"
          ><img class="icons-tools" src="@/assets/scss/icons/girdAdd.svg" alt=""
        /></el-button>
      </el-badge>
      <div
        v-if="activeMenu"
        class="container-columns"
        style="display: flex; flex-direction: column"
      >
        <div v-for="item in options" :key="item.value">
          <el-checkbox v-model="item.checked">
            {{ item.text }}
          </el-checkbox>
        </div>
        <div class="filters-footer">
          <button class="filters-footer__buttons" @click="changeCheckbox()">Apply</button>
          <button class="filters-footer__buttons" @click="resetColumns()">Reset</button>
        </div>
      </div>
    </i>
  </div>
</template>

<script>
export default {
  name: "AddColumnsGrid",
  props: {
    active: {
      type: Boolean,
    },
    data: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  directives: {
    "click-outside": {
      bind: (el, binding, vnode) => {
        el.clickOutsideEvent = (event) => {
          if (!(el === event.target || el.contains(event.target))) {
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unbind: (el) => {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
    },
  },
  data() {
    return {
      activeMenu: false,
      numberColumns: 0,
      columnsSelected: "",
      options: [],
      optionsDates: [
        {
          value: 1,
          checked: false,
          text: "Actual Pickup Date",
          label: "actual_pickup_date",
        },
        {
          value: 2,
          checked: false,
          text: "Estimated Delivery Date",
          label: "expected_delivery_date",
        },
        {
          value: 3,
          checked: false,
          text: "Actual Delivery Date",
          label: "actual_delivery_date",
        },
        {
          value: 4,
          checked: false,
          text: "Billing Reference",
          label: "billing_reference",
        },
        {
          value: 5,
          checked: false,
          text: "Customer",
          label: "customer",
        },
      ],
      optionsInsurance: [
        {
          value: 1,
          checked: false,
          text: "Marks or Numbers",
          label: "marks_or_numbers",
        },
        {
          value: 2,
          checked: false,
          text: "Number Reference",
          label: "reference_number",
        },
      ],
    };
  },
  mounted() {
    if (this.type === "myLoad") {
      this.options = this.optionsDates;
    } else if (this.type === "insurance") {
      this.options = this.optionsInsurance;
    }
  },
  watch: {
    active() {
      this.activeMenu = !this.active ? false : this.activeMenu;
    },
    data() {
      if (this.data !== "" && this.data !== null) {
        this.options.forEach((object) => {
          object.checked = this.data.includes(object.label);
          this.numberColumns += object.checked ? 1 : 0;
        });
      }
    },
  },
  methods: {
    changeStatusColumns() {
      this.activeMenu = !this.activeMenu;
      this.$emit("changeStatusColumns", true);
    },
    changeCheckbox() {
      this.columnsSelected = "";
      this.numberColumns = 0;
      this.options.forEach((object) => {
        if (object.checked) {
          this.numberColumns += 1;
          this.columnsSelected = `${this.columnsSelected},${object.label}`;
        }
      });
      this.$emit("addColumns", this.columnsSelected);
      this.activeMenu = false;
    },
    resetColumns() {
      this.options.forEach((option) => {
        option.checked = false;
      });
      this.$emit("addColumns", "");
    },
    hide() {
      this.activeMenu = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-columns {
  border: 2px solid var(--color-border-container);
  border-radius: 5px;
  padding: 8px;
  width: auto;
  background: #fff;
  position: absolute;
  transform: translate3d(73px, 30px, 0px);
  top: 89px;
  margin: 7px -215px 0;
  will-change: transform;
  z-index: 12;
  float: left;
  min-width: 10rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  font-family: $font-family-portal;
}

.container-filter {
  border: 2px solid var(--color-border-container);
  color: var(--color-primary-company);
  background-color: #efefef;
  padding: 0.4rem 0.4rem;
  font-weight: 600;
  font-size: 13px;
}
.icons-tools {
  width: 18px;
  height: 18px;
}
::v-deep.item {
  margin-top: 0px;
  margin-right: 0px;
  z-index: 11;
}
::v-deep.el-badge__content.is-fixed {
  position: absolute;
  top: 7px;
  right: 28px;
  transform: translateY(-50%) translateX(100%);
}
::v-deep .el-badge__content,
.el-progress.is-exception .el-progress-bar__inner {
  background-color: $color-border-container;
}
::v-deep .el-badge__content.is-fixed {
  top: 5px;
  right: 22px;
  z-index: 10;
}
::v-deep .el-badge__content {
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-size: 11px;
  height: 18px;
  line-height: 18px;
  padding: 0 5px;
  text-align: center;
  white-space: nowrap;
  border: 1px solid #fff;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: $color-border-container;
  border-color: $color-border-container;
}
::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 8px;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  background-color: #fff;
  z-index: 1;
  transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
    background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
}
::v-deep .el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 11px;
  font-style: initial;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #606266;
}
.filters-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  margin-top: 6px;
  gap: 8px;

  &__buttons {
    border-radius: 15px;
    border: 1px solid $color-border-container;
    background-color: #fff;
    color: $color-border-container;
    margin: 2px;
    font-weight: bold;
    height: 25px;
    width: 70px;
    font-size: 11px;
    &:hover {
      background-color: $color-border-container;
      color: #fff;
    }
    &:focus-within {
      background-color: $color-border-container;
      color: #fff;
    }
  }
  &__buttons-inactive {
    background-color: #f4f4f4;
    color: #c8c8ca;
    border: 1px solid #f4f4f4;
    border-radius: 15px;
    margin: 2px;
    font-weight: bold;
    height: 25px;
    width: 70px;
    font-size: 11px;
  }
}
</style>
