export default {
  methods: {
    validateDateType(loadStatus, dateNumber) {
      let data = {};
      if (loadStatus === 'Quoted') {
        if (dateNumber === 1) {
          data = {
            tooltip: 'crd',
            title: 'CRD: ',
            date: 'created_date'
          };
        }
        if (dateNumber === 2) {
          data = {
            tooltip: 'epd',
            title: 'EPD: ',
            date: 'expected_ship_date'
          };
        }
      }
      if (loadStatus === 'Assigned' || loadStatus === 'Truck Pending') {
        if (dateNumber === 1) {
          data = {
            tooltip: 'crd',
            title: 'CRD: ',
            date: 'created_date'
          };
        }
        if (dateNumber === 2) {
          data = {
            tooltip: 'epd',
            title: 'EPD: ',
            date: 'expected_ship_date'
          };
        }
      }
      if (loadStatus === 'In Transit') {
        if (dateNumber === 1) {
          data = {
            tooltip: 'apd',
            title: 'APD: ',
            date: 'actual_pickup_date'
          };
        }
        if (dateNumber === 2) {
          data = {
            tooltip: 'edd',
            title: 'EDD: ',
            date: 'expected_delivery_date'
          };
        }
      }
      if (loadStatus === 'Delivered') {
        if (dateNumber === 1) {
          data = {
            tooltip: 'apd',
            title: 'APD: ',
            date: 'actual_pickup_date'
          };
        }
        if (dateNumber === 2) {
          data = {
            tooltip: 'add',
            title: 'ADD: ',
            date: 'actual_delivery_date'
          };
        }
      }
      return data;
    },
  },
};
