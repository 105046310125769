const myLoadsFilterSchema = {
  shp: "",
  origin: "",
  destination: "",
  carrier: "",
  created_date_gt: "",
  created_date_lt: "",
  actual_pickup_date_gt: "",
  actual_pickup_date_lt: "",
  actual_delivery_date_gt: "",
  actual_delivery_date_lt: "",
  expected_ship_date_gt: "",
  expected_ship_date_lt: "",
  expected_delivery_date_gt: "",
  expected_delivery_date_lt: "",
  included_accessorials: "",
  state_search: "",
  customer: "",
  limit: "",
  skip: "",
  order_by: "",
  statuses: [],
  modes: [],
  accessorial_id: ""
};

export default myLoadsFilterSchema;
