/* eslint-disable max-len */
export default {
  data() {
    return {
      arrayCarriers: [
        { name: "2 DAY TRANSPORT", link: "http://www.2daytranspo.com/" },
        { name: "305 EXPRESS USA", link: "" },
        { name: "A. Duie Pyle", link: "https://www.aduiepyle.com/MyPyle/MyPyleHome" },
        {
          name: "AAA Cooper Transportation",
          link: "https://www.aaacooper.com/pwb/Dashboard.aspx?id=$PRO&PG=ProNumber",
        },
        {
          name: "AAA Cooper",
          link: "https://www.aaacooper.com/pwb/Dashboard.aspx?id=$PRO&PG=ProNumber",
        },
        {
          name: "ABF Freight System, Inc.",
          link: "https://arcb.com/panther-premium/tracking#/$PRO",
        },
        {
          name: "AMA TRANSPORTATION",
          link: "http://tracking.carrierlogistics.com/scripts/ama.pol/facts.htm$PRO",
        },
        {
          name: "Averitt Express, Inc.",
          link: "https://tools.averitt.com/tracking/trackLTLById?toolTab=track&serviceType=LTL&resultsPageTitle=LTL+Tracking+by+PRO+and+BOL&searchType=LTL&trackPro=$PRO",
        },
        { name: "Best Overnite Express", link: "" },
        {
          name: "Best Yet Express",
          link: "https://tracking.carrierlogistics.com/scripts/btyt.pol/facts.htm",
        },
        {
          name: "Central Transport",
          link: "https://www.centraltransport.com/tools/track-shipment/PRO=[$PRO]",
        },
        { name: "CROSS COUNTRY", link: "https://auth.ccfs.com/track/search/$PRO" },
        {
          name: "Daylight Transport",
          link: "https://mydaylight.dylt.com/external/shipment?probill=$PRO",
        },
        {
          name: "DIAMOND LINE DELIVERY SYSTEMS, INC.v",
          link: "https://tracking.carrierlogistics.com/scripts/dlds.pol/boldetail.htm?wbtn=PRO&wpro1=$PRO&seskey=&nav=top&language=",
        },
        {
          name: "Dohrn Transfer Company",
          link: "https://www.dohrn.com/ship/tracking-reports/track-by-pro?prolist=$PRO",
        },
        {
          name: "Double D Express",
          link: "https://tracking.carrierlogistics.com/scripts/dbde.pol/boldetail.htm?wbtn=PRO&wpro1=$PRO&seskey=&nav=&language=",
        },
        { name: "EDI Express, Inc", link: "https://my.ediexpressinc.com/pro_pu_trace.asp" },
        {
          name: "Estes Express",
          link: "https://www.estes-express.com/myestes/tracking/shipments?type=PRO&query=$PRO",
        },
        {
          name: "FedEx Freight (Priority)",
          link: "https://www.fedex.com/fedextrack/?trknbr=$PRO&trkqual=~$PRO~FDFR",
        },
        {
          name: "FedEx Freight (Economy)",
          link: "https://www.fedex.com/fedextrack/?trknbr=$PRO&trkqual=~$PRO~FDFR",
        },
        {
          name: "FEDEX FREIGHT (PRIORITY)",
          link: "https://www.fedex.com/fedextrack/?trknbr=$PRO&trkqual=~$PRO~FDFR",
        },
        {
          name: "FEDEX FREIGHT (ECONOMY)",
          link: "https://www.fedex.com/fedextrack/?trknbr=$PRO&trkqual=~$PRO~FDFR",
        },
        {
          name: "FEDEX FREIGHT",
          link: "https://www.fedex.com/fedextrack/?trknbr=$PRO&trkqual=~$PRO~FDFR",
        },
        {
          name: "FEDEX PACKAGING",
          link: "https://www.fedex.com/fedextrack/?trknbr=$PRO&trkqual=12026~$PRO~FDEG",
        },
        { name: "Forward Air Solutions", link: "https://www.forwardair.com/tracking?numbers=$PRO" },
        {
          name: "Holland",
          link: "https://my.hollandregional.com/tools/track/shipments?referenceNumber=$PRO&referenceNumberType=PRO&time=",
        },
        {
          name: "HOTLINE DELIVERY SYSTEMS",
          link: "https://hotline.e-courier.com/hotline/home/Wizard_tracking.asp?UserGUID=",
        },
        { name: "ISHARED TRANSPORTATION", link: "https://ishared.com/" },
        {
          name: "JTS EXPRESS",
          link: "http://www.jtsexpress.com/cgi-bin/wbprotrk?wbfb=$PRO&button=Trace",
        },
        {
          name: "Midwest Motor Express",
          link: "https://mmeinc.com/pwb/Transit/ProTrackResults.aspx?ProNum=$PRO&AllAccounts=true",
        },
        { name: "MOUNTAIN VALLEY EXPRESS (GLS)", link: "https://freight.gls-us.com/tracking" },
        {
          name: "New Penn",
          link: "https://tools.newpenn.com/tools/track/shipments?referenceNumber=$PRO&referenceNumberType=PRO%20&time=$PRO",
        },
        { name: "OAK HARBOR FREIGHT LINES", link: "https://www.oakh.com/page/tracing" },
        {
          name: "Pace Motor Lines",
          link: "http://tracking.carrierlogistics.com/scripts/pac.pol/facts.htm",
        },
        { name: "PARAGON TRUCKING", link: "http://www.paragontrucking.com/" },
        {
          name: "Peninsula Truck Lines",
          link: "https://peninsulatruck.com/_/#/track/?pro_number=$PRO",
        },
        { name: "PITT OHIO", link: "https://pittohio.com/myPittOhio/Shipping/QuickTrace/Post" },
        {
          name: "R+L Carriersv",
          link: "https://www2.rlcarriers.com/freight/shipping/shipment-tracing?pro=$PRO&docType=PRO&source=web",
        },
        {
          name: "RIST TRANSPORT, LTD.",
          link: "http://webtools.wadhams.com/Prod_WebProducts/",
        },
        {
          name: "ROADRUNNER TRANSPORTATION",
          link: "http://tools.rrts.com/LTLTrack/?searchValues=$PRO",
        },
        { name: "SAIA Motor Freight", link: "https://www.saia.com/track/details;pro=$PRO" },
        {
          name: "Southeastern Freight",
          link: "https://www.sefl.com/webconnect/tracing?Type=PN&RefNum1=$PRO",
        },
        {
          name: "Southwestern Motor Transport",
          link: "https://profoundui.smtl.com/profoundui/start?pgm=profoundui/mwp705c&p1=0&l1=1&p2=none&l2=4&p3=none&l3=4",
        },
        {
          name: "SPECIALIZED FREIGHT CARRIERS (SFC)",
          link: "https://tracking.carrierlogistics.com/scripts/sfc.pol/facts.htm",
        },
        {
          name: "STERLING TRANSPORTATION",
          link: "https://sterling.ts2000.net/Login/QuickTrack/?SearchOn=HWBNumber&SearchFor=&SearchOn=HWBNumber&SearchFor=$PRO",
        },
        {
          name: "TAX AIRFREIGHT",
          link: "https://tracking.carrierlogistics.com/scripts/taxa.pol/facts.htm?startpage=protrace.htm&refno=$PRO",
        },
        {
          name: "TFORCE FREIGHT",
          link: "https://www.tforcefreight.com/ltl/apps/Tracking?proNumbers=$PRO",
        },
        {
          name: "THOROUGHBRED DIRECT INTERMODAL SERVICES INC -NORFOLK SOUTHERN",
          link: "https://www.ns-direct.com/tft",
        },
        {
          name: "USF Reddaway",
          link: "https://my.reddawayregional.com/tools/track/shipments?referenceNumber=$PRO&referenceNumberType=PRO&time=",
        },
        {
          name: "Ward Trucking",
          link: "https://wardtlctools.com/wardtrucking/traceshipment/create",
        },
        {
          name: "XPO Logistics Freight, Inc.",
          link: "https://ext-web.ltl-xpo.com/public-app/shipments?referenceNumber=$PRO",
        },
        {
          name: "YRC Freight",
          link: "https://my.yrc.com/tools/track/shipments?referenceNumber=$PRO&referenceNumberType=PRO&time=",
        },
        {
          name: "UPS GROUND (UNI)",
          link: "https://www.ups.com/track?loc=en_US&requester=ST/",
        },
      ],
    };
  },
  methods: {
    searchTrackingLink(name, proNumber) {
      const objectCarrier = this.arrayCarriers.find((carrier) => carrier.name.includes(name)) || {};
      const link =
        objectCarrier.link !== undefined && objectCarrier.link !== ""
          ? objectCarrier.link.replace("$PRO", proNumber)
          : "";
      return link;
    },
  },
};
