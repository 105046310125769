/* eslint-disable func-names */
/* eslint-disable consistent-return */
import LoadStatusType from "./LoadStatusType";

export default {
  mixins: [LoadStatusType],
  props: {
    data: Array,
    limit: Number,
    perPage: Number,
    firstItemsLoaded: Boolean,
    loadingItems: Boolean,
  },
  data() {
    return {
      loadNumberSort: "",
      originSort: "",
      destinationSort: "",
      selectedSort: this.$t("myLoads.loadNumber"),
      currentPage: 1,
      loadingData: false,
      notFound: false,
      skip: 0,
      noMoreData: false,
      selectedAccount: false, // methods for loadsMobile
      perPageOptions: [25, 50, 100],
      sortByOptions: [
        {
          label: this.$t("myLoads.loadNumber"),
          name: "loadNumberSort",
          type: "",
        },
        {
          label: this.$t("myLoads.origin"),
          name: "originSort",
          type: "",
        },
        {
          label: this.$t("myLoads.destination"),
          name: "destinationSort",
          type: "",
        },
      ],
      loadStatusOptions: [
        { value: "filterQuoted", text: this.$t("myLoads.quoted"), checked: false },
        { value: "filterAssigned", text: this.$t("myLoads.assigned"), checked: false },
        { value: "filterInTransit", text: this.$t("myLoads.inTransit"), checked: false },
        { value: "filterDelivered", text: this.$t("myLoads.delivered"), checked: false },
        { value: "filterCancelled", text: this.$t("myLoads.cancelled"), checked: false },
      ],
      filterList: [],
      filterQuoted: ["Unassigned", "Quotes Requested", "Quotes Received"],
      filterAssigned: ["Tendered", "Assigned", "Dispatched"],
      filterInTransit: ["In transit"],
      filterDelivered: ["Delivered", "completed"],
      filterCancelled: ["Cancelled", "declined", "Expired"],
      dataFields: [
        {
          key: "name",
          label: "loadNumber",
          tdClass: "columnClass",
        },
        {
          key: "load_status",
          label: "loadStatus",
          tdClass: "columnClass",
        },
        {
          key: "origin",
          label: "origin",
          tdClass: "columnClass",
        },
        {
          key: "destination",
          label: "destination",
          tdClass: "columnClass",
        },
        {
          key: "pro_number",
          label: "proNumber",
          tdClass: "columnClass",
        },
        {
          key: "carrier.name",
          label: "carrier",
          tdClass: "columnClass",
        },
        {
          key: "date_1",
          label: "dates",
          tdClass: "columnClass",
        },
        {
          key: "date_2",
          label: "dates",
          tdClass: "columnClass",
        },
        {
          key: "revenue_cost",
          label: "price",
          tdClass: "columnClass",
        },
      ],
      table: null,
    };
  },
  async created() {
    this.loadingData = true;
    // Check if there is data to show
    if (this.data.length === 0 && !this.loadingItems) {
      this.notFound = true;
    } else {
      this.notFound = false;
    }
    this.loadingData = false;
    document.body.onmousedown = function (e) {
      if (e.button === 1) return false;
    };
  },
  mounted() {
    this.$nextTick(() => {
      // eslint-disable-next-line max-len
      this.table = document.querySelector(".table-responsive") || document.querySelector(".row-container");
    });
  },
  computed: {
    rows() {
      return this.data.length;
    },
  },
  watch: {
    // Methods to be used when there is a change in the filters
    loadStatusOptions: {
      deep: true,
      handler() {
        this.filterList = [];
        for (let i = 0; i < this.loadStatusOptions.length; i += 1) {
          if (this.loadStatusOptions[i].checked) {
            this.filterList.push(...this.$data[this.loadStatusOptions[i].value]);
          }
        }
        this.$emit("changeFilterBy", this.filterList);
      },
    },
    table() {
      this.table.addEventListener('scroll', () => {
        this.wheelEventHandling();
      });
    }
  },
  methods: {
    rowSelected(item, clickType) {
      const loadStatusType = this.setLoadStatusColor(item.load_status);
      let routeName = "";
      if (loadStatusType.label === "Quoted") {
        routeName = "CarriersInfo";
      } else {
        routeName = "LoadsDetails";
      }
      if (clickType === "middleClick") {
        const routeData = this.$router.resolve({
          name: routeName,
          params: { loadId: item.id },
        });
        window.open(routeData.href, "_blank");
      } else {
        this.$router.push({
          name: routeName,
          params: { loadId: item.id },
        });
      }
      this.$store.commit("load/setSearchText", "");
    },
    changeSortBy(sortName) {
      if (!this.loadingItems) {
        switch (this.$data[sortName]) {
          case "asc":
            this.$data[sortName] = "desc";
            break;

          case "desc":
            this.$data[sortName] = "";
            break;

          case "":
            this.$data[sortName] = "asc";
            break;

          default:
            break;
        }
        const sortInfo = {
          name: sortName,
          type: this.$data[sortName],
        };
        this.$emit("changeSortBy", sortInfo);
        // Reset the other sort types
        switch (sortName) {
          case "loadNumberSort":
            this.selectedSort = "Load Number";
            this.originSort = "";
            this.destinationSort = "";
            break;
          case "originSort":
            this.selectedSort = "Origin";
            this.loadNumberSort = "";
            this.destinationSort = "";
            break;
          case "destinationSort":
            this.selectedSort = "Destination";
            this.loadNumberSort = "";
            this.originSort = "";
            break;

          default:
            break;
        }
      }
    },
    scrollToStartElements() {
      const table = document.querySelector(".table-responsive");
      table.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    wheelEventHandling() {
      if (
        Math.ceil(this.table.scrollTop + this.table.clientHeight)
        >= this.table.scrollHeight - 50
      ) {
        if (!this.loadingItems) {
          this.$emit("loadMoreItems");
        }
      }
    },
  },
};
