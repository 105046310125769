import dayjs from "dayjs";

export default {
  data() {
    return {
      currentDate: null,
    };
  },
  created() {
    this.currentDate = dayjs().format("MM-DD-YYYY");
  },
  methods: {
    compareDates(loadDate, loadStatus) {
      if (loadStatus.label.toLowerCase() === 'assigned'
        || loadStatus.label.toLowerCase() === 'in transit') {
        const formatLoadDate = dayjs(loadDate).format("MM-DD-YYYY");
        const loadDateIsBeforeCurrentDate = dayjs(formatLoadDate).isBefore(this.currentDate);
        if (loadDateIsBeforeCurrentDate) {
          return 'expired-date';
        }
        return null;
      }
      return null;
    },
  },
};
