<template>
  <div
    v-if="params.value"
    style="display: flex; flex-wrap: wrap; justify-content: center"
  >
    <div v-for="(item, key) in icons" :key="key">
      <i
        v-b-popover.hover.top="validateTooltip(item.options)"
        v-if="validateIcon(item.options)"
      >
        <img class="icon-filters ml-1" :src="item.src" alt="" />
      </i>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
export default {
  name: "load-accesorials",
  data() {
    return {
      icons: {
        hazmat: {
          src: require("@/assets/scss/icons/hazmat.svg"),
          options: [
            "Hazmat",
            "Hazmat Explosives 1.4",
            "Hazmat Toxic or Poison 6.1",
          ],
        },
        airport: {
          src: require("@/assets/scss/icons/airport.svg"),
          options: [
            "Airport Pick Up",
            "Airport Delivery",
            "CFS Pick Up",
            "CFS Delivery",
          ],
        },
        residential: {
          src: require("@/assets/scss/icons/residential.svg"),
          options: ["Residential Pick Up", "Residential Delivery"],
        },
        limited: {
          src: require("@/assets/scss/icons/limited.svg"),
          options: ["Limited access Pick Up", "Limited Access Delivery"],
        },
        guaranteed: {
          src: require("@/assets/scss/icons/guaranteed.svg"),
          options: ["Guaranteed transit time", "Expedited"],
        },
        insurance: {
          src: require("@/assets/scss/icons/insurance.svg"),
          options: ["Insurance"],
        },
      },
    };
  },
  computed: {
    accesorialsByLoad() {
      return this.params.value.split(";");
    },
  },
  methods: {
    validateIcon(value) {
      if (!this.accesorialsByLoad) return false;
      return this.accesorialsByLoad.some((option) => value.includes(option.trim()));
    },
    validateTooltip(value) {
      const array = value.filter((tooltip) => this.accesorialsByLoad.includes(tooltip));
      return array.join(", ");
    },
  },
};
</script>
<style lang="scss">
.icon-filters {
  width: 13px;
  height: 13px;
}
.icon-filters:hover {
  color: $color-border-container;
}
</style>
