<template>
  <div class="my-loads">
    <div class="my-loads__title"></div>
    <div class="my-loads__filters" :class="{ 'disable-filters': loadingItems }">
      <div>
        <span>{{ this.$t("myLoads.myLoadsTable.filterBy") }}</span>
        <Dropdown
          class="my-loads__filters__option border-right-filter"
          :options="loadStatusOptions"
          optionLabel="text"
          optionValue="value"
          :placeholder="$t('myLoads.loadStatus')"
          :showToggleAll="false"
          :disabled="loadingData"
        >
          <template #option="slotProps">
            <div class="p-dropdown-car-option">
              <b-form-checkbox
                v-model="slotProps.option.checked"
                :binary="true"
                @click.stop
                @click.prevent
                :disabled="loadingItems"
                class="mr-2"
                :id="slotProps.option.text"
              />
              <label :for="slotProps.option.text">{{ slotProps.option.text }}</label>
            </div>
          </template>
        </Dropdown>
      </div>
      <div>
        <span>{{ this.$t("myLoads.myLoadsTable.sortBy") }}</span>
        <Dropdown
          class="my-loads__filters__option border-right-filter"
          :options="sortByOptions"
          optionLabel="text"
          optionValue="value"
          :placeholder="selectedSort"
          :showToggleAll="false"
        >
          <template #option="slotProps">
            <div class="p-dropdown-car-option" @click="changeSortBy(slotProps.option.name)">
              <span class="dropdown-option">
                {{ slotProps.option.label }}
                <div
                  v-show="
                    $data[slotProps.option.name] === 'asc' ||
                    $data[slotProps.option.name] === 'desc'
                  "
                >
                  <i
                    class="el-icon-caret-top"
                    :class="$data[slotProps.option.name] !== 'asc' ? 'icon-disabled' : ''"
                  ></i>
                  <i
                    class="el-icon-caret-bottom"
                    :class="$data[slotProps.option.name] !== 'desc' ? 'icon-disabled' : ''"
                  ></i>
                </div>
              </span>
            </div>
          </template>
        </Dropdown>
      </div>
    </div>
    <div class="row-collapse">
      <div class="loading" v-show="loadingItems">
        <b-icon icon="truck" animation="cylon" font-scale="4"></b-icon>
        <FadeAnimationTextArray :textArray="loadingTextArray" />
      </div>
      <div class="row-container accordion" role="tablist">
        <div
          v-for="item in data"
          :key="item.id"
          class="row-content"
          no-body
          v-b-toggle="'collapse-information' + item.id"
        >
          <div class="header-card" header-tag="header" role="tab">
            <div class="header-information" :class="item.collapsed">
              <p class="header-information__name">
                {{ item.name }}
              </p>
              <div
                class="header-information__status"
                :style="'color: ' + setLoadStatusColor(item.load_status).color"
              >
                {{ setLoadStatusColor(item.load_status).label }}
              </div>
            </div>
            <div>
              <span class="header-line"></span>
            </div>
            <div class="header-information" :class="item.collapsed">
              <p class="header-information__name">
                {{ $t("myLoads.myLoadsTable.destiny") }}
              </p>
              <p class="header-information__name">
                {{ item.destination.substr(0, 8) }}
              </p>
            </div>
            <div class="header-icon">
              <i class="ion ion-chevron-forward-outline"></i>
            </div>
          </div>
          <b-collapse
            class="body"
            :id="'collapse-information' + item.id"
            accordion="my-accordion"
            role="tabpanel"
            @show="showCard(item)"
            @hide="hideCard(item)"
          >
            <div class="account-information">
              <div class="account-information__row">
                <div class="account-information__row--text">
                  <label>{{ $t("myLoads.myLoadsTable.location") }}</label>
                  <span>{{ item.origin }}</span>
                </div>
                <div class="account-information__row--text">
                  <label>{{ $t("myLoads.myLoadsTable.destiny") }}</label>
                  <span>{{ item.destination }}</span>
                </div>
              </div>
              <div class="account-information__row">
                <div class="account-information__row--text">
                  <label>{{ $t("myLoads.myLoadsTable.status") }}</label>
                  <span>{{ setLoadStatusColor(item.load_status).label }}</span>
                </div>
                <div class="account-information__row--text">
                  <label>{{ $t("myLoads.myLoadsTable.number") }}</label>
                  <span>{{ item.name }}</span>
                </div>
              </div>
              <div class="account-information__row">
                <div class="account-information__row--text">
                  <label>{{ $t("myLoads.myLoadsTable.pro") }}</label>
                  <span>{{ item.pro_number }}</span>
                </div>
                <div class="account-information__row--text">
                  <label>{{ $t("myLoads.myLoadsTable.cost") }}</label>
                  <template v-if="setLoadStatusColor(item.load_status).label === 'Delivered'">
                    <span v-if="showCost">{{ item.revenue_cost | priceToUsd }}</span>
                    <span v-else> Invoice pending </span>
                  </template>
                  <template v-else>
                    <span>{{ item.revenue_cost | priceToUsd }}</span>
                  </template>
                </div>
              </div>
              <!-- Load dates -->
              <div class="account-information__row">
                <!-- Date 1 -->
                <div class="account-information__row--text">
                  <label>
                    {{
                      $t(
                        "myLoads.myLoadsTable." +
                          validateDateType(setLoadStatusColor(item.load_status).label, 1).tooltip
                      )
                    }}
                  </label>
                  <span
                    v-if="
                      item[validateDateType(setLoadStatusColor(item.load_status).label, 1).date]
                    "
                  >
                    {{
                      item[validateDateType(setLoadStatusColor(item.load_status).label, 1).date]
                        | dateFormat
                    }}
                  </span>
                </div>
                <!-- Date 2 -->
                <div class="account-information__row--text">
                  <label>
                    {{
                      $t(
                        "myLoads.myLoadsTable." +
                          validateDateType(setLoadStatusColor(item.load_status).label, 2).tooltip
                      )
                    }}
                  </label>
                  <span
                    v-if="
                      item[validateDateType(setLoadStatusColor(item.load_status).label, 2).date]
                    "
                    :class="
                      compareDates(item.expected_ship_date, setLoadStatusColor(item.load_status))
                    "
                  >
                    {{
                      item[validateDateType(setLoadStatusColor(item.load_status).label, 2).date]
                        | dateFormat
                    }}
                  </span>
                </div>
              </div>
              <!-- Carrier -->
              <div class="account-information__row">
                <div v-if="item.carrier" class="account-information__row--text">
                  <label>{{ $t("myLoads.myLoadsTable.transporter") }}</label>
                  <span>{{ item.carrier.name }}</span>
                </div>
                <div
                  class="account-information__row--text-info"
                  v-on:click.middle="rowSelected(item, 'middleClick')"
                >
                  <label @click="rowSelected(item, 'normalClick')">{{
                    $t("myLoads.myLoadsTable.information")
                  }}</label>
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </div>
    <div class="my-loads__not-found" v-if="notFound">
      <i class="ion ion-file-tray-outline my-loads__not-found__icon"></i>
      <p>No loads were found.</p>
    </div>
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";
import MyLoads from "../../Extend/MyLoads";
import LoadStatusType from "../../Extend/LoadStatusType";
import ValidateLoadDateType from "../../Extend/ValidateLoadDateType";
import FadeAnimationTextArray from "../../components/FadeAnimationTextArray.vue";
import LoadingTextArray from "../../utils/LoadingTextArray";
import LoadDatesValidation from "../../Extend/LoadDatesValidation";

export default {
  name: "MyLoadsResponsive",
  mixins: [MyLoads, LoadStatusType, ValidateLoadDateType, LoadDatesValidation],
  components: {
    Dropdown,
    FadeAnimationTextArray,
  },
  props: {
    showCost: Boolean,
    filters: Object
  },
  data() {
    return {
      pageSelector: 0,
      loadingTextArray: LoadingTextArray.myLoads,
    };
  },
  created() {
    this.pageSelector = this.perPage;
  },
  watch: {
    data() {
      if (!this.loadingItems && this.data.length === 0) {
        this.notFound = true;
        return;
      }
      this.notFound = false;
    },
  },
  methods: {
    async showCard(item) {
      this.selectedAccount = item;
      item.collapsed = false;
    },
    hideCard(item) {
      item.collapsed = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/components/Checkboxes.scss";

.my-loads {
  padding-bottom: 5%;
  padding-top: 1.7rem;
  width: 97%;
  height: 100%;
  margin: 0px auto;
  position: relative;
  &__not-found {
    margin-top: -450px;
    &__icon {
      font-size: 50px;
    }
  }
  &__filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 10px;
    // @include font-small-button-text;
    font-weight: 600;
    &__option {
      margin-bottom: 5px;
      border: none;
      cursor: default !important;
    }
  }
}
.loading {
  @include loading-overlay;
  background-color: transparent !important;
  svg,
  p {
    color: $color-primary-company;
  }
}
.header-line {
  display: flex;
  border-left: thin solid $color-border-container;
  height: 35px;
}
.dropdown-option {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  div {
    display: flex;
    flex-direction: column;
    padding-left: 5%;
    cursor: pointer;
  }
}

.disable-filters {
  pointer-events: none;
  opacity: 0.4;
}

.icon-disabled {
  color: rgb(161, 161, 161);
}
//CSS for the per page buttons
::v-deep .btn-group,
.btn-group-vertical {
  margin-left: 5px;
}
::v-deep .btn-group .btn {
  border: 2px solid $color-border-container;

  color: $color-primary-company;
  background-color: $color-gray-light;
}
::v-deep .btn-secondary:not(:disabled):not(.disabled).active {
  border: 2px solid $color-border-container;
  border-radius: 5px;
  color: $color-white;
  background-color: $color-border-container;
}
::v-deep .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-radius: 5px;
  margin-right: 5px;
}
::v-deep .btn-group > .btn:last-child:not(.dropdown-toggle) {
  border-radius: 5px;
}
::v-deep .btn {
  padding: 0.12rem 0.42rem;
  margin-bottom: 0.13rem;
  font-family: $font-family-portal;
  font-weight: 600;
  @include font-small-button-text;
}
//CSS for the filter dropdown
::v-deep .p-dropdown .p-dropdown-label {
  font-family: $font-family-portal;
  font-weight: 600;
  @include font-small-button-text;
  color: $color-primary-company;
  border-radius: 10px;
  padding: 0;
}
::v-deep .p-dropdown .p-dropdown-label.p-placeholder {
  color: $color-secondary-button-text;
  padding: 0;
}
::v-deep .p-dropdown-items-wrapper {
  max-height: 100% !important;
}
::v-deep .p-dropdown-panel {
  border-radius: 10px;
}
::v-deep .p-dropdown-panel .p-dropdown-items {
  background-color: white;
  border: 1px solid $color-border-container;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
::v-deep
  .p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background-color: transparent;
}
::v-deep .p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
  border-color: none;
}
::v-deep .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  padding: 0.18rem 0.8rem;
  color: $color-primary-company;
  font-family: $font-family-portal;
  @include font-table-text;
  font-weight: normal;
  margin: 6px 0;
  width: 100%;
}
::v-deep .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: transparent;
}
::v-deep .p-checkbox {
  & span:before {
    content: none;
  }
}

// CSS for the checkbox
::v-deep .p-dropdown-car-option {
  display: flex;
  align-items: center;
  & label {
    margin: 0;
  }
}
//CSS for collapse
.row-collapse {
  position: relative;
}
.row-container {
  height: 72vh;
  padding: 2px;
  overflow: auto;
  @extend %scroll-bar-styles;
}
.row-container::-webkit-scrollbar {
  display: none;
}
.row-content {
  @include collapse-container-styles;
}
.header-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .header-information {
    &__name {
      display: flex;
      align-items: flex-start;
      margin: 0px;
      font-weight: bold;
      span {
        margin-left: 10px;
      }
    }
    &__status {
      color: #00ff00;
      display: flex;
      align-items: flex-start;
      margin: 0px;
      text-align: start;
      font-weight: 600;
    }
  }
  .header-icon {
    font-size: 25px;
  }
}
.header-information__status {
  width: 120px;
}
.body {
  width: 100%;
  margin-top: 10px;
  border-top: 1px solid $color-border-container;
  .account-information {
    width: 100%;
    &__row {
      display: grid;
      grid-template-columns: 55% 45%;
      margin: 10px 0;
      &--text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
        label {
          margin: 0;
          font-weight: 600;
        }
      }
      &--text-info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        text-decoration: underline;
        color: $color-secondary-button-text;
        label {
          margin: 0;
          font-weight: 700;
          text-align: left;
          &:hover {
            padding: 1px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.not-collapsed .header-card .header-icon {
  transform: rotate(90deg);
  transition: 0.4s all;
}
.collapsed .header-card .header-icon {
  transform: rotate(0deg);
  transition: 0.6s all;
}

::v-deep .btn-group-toggle {
  max-height: 30px;
}
.expired-date {
  color: red;
}
</style>
